// ROBOTFILTER!
//
// Ths ultimate ajaxy form auto-submission result reloading tool
//
// @author jeff@imarc.com
// @author shawna@imarc.com

;(function($) {
    var RoboFilter = function(elem, options) {
        var $form    = $(elem);
        var $filterButton = $('.filter-trigger');
        var $results = $('[data-filter-results]');

        $form.submit(function(e) {
            // prevent form submission
            e.preventDefault();

            // fade the filtered items
            $results.css('opacity', 0.3);

            // build the url to load
            var query = $(this).serialize();
            var url = window.location.pathname;

            if (query) {
                url += '?' + query;
            }

            // use history API to alter the location url
            if (window.history.replaceState) {
                window.history.replaceState(
                    {}, document.title, url
                );

            };

            // load in results via ajax and replace old results
            $results.load(url + ' [data-filter-results]>*', function() {
                // unfade the filtered items once loaded
                $results.css('opacity', 1);
                if (!($results[0].childElementCount)) {
                   $results.html('<p class="no-results">No Results Found</p>');
                }
            })

        });

        /// If filter needs to be triggered by something other than form element. 
        /// Maker sure element has a data-key and data-value pair associated with it.


        $filterButton.on('click', function() {
            var locationSlug = $(this).attr('data-value');
            var locationKey = $(this).attr('data-key');

            // fade the filtered items
            $results.css('opacity', 0.3);

            // build the url to load
            var query = locationKey + '=' + locationSlug;
            var url = window.location.pathname;

            if (query) {
                url += '?' + query;
            }

            // use history API to alter the location url
            if (window.history.replaceState) {
                window.history.replaceState(
                    {}, document.title, url
                );

            };

            // load in results via ajax and replace old results
            $results.load(url + ' [data-filter-results]>*', function() {
                // unfade the filtered items once loaded
                $results.css('opacity', 1);
                if (!($results[0].childElementCount)) {
                   $results.html('<p class="no-results">No Results Found</p>');
                }
            })
        });

        // select boxes
        $form.find('select[data-filter]').each(function() {
            var $filter = $(this);

            // submit form on change
            $filter.change(function() {
                $form.submit();
            });
        });
    }

    $.fn.robofilter = function(options) {
        this.each(function() {
            return new RoboFilter(this, options);
        });
    };
})(jQuery);

