import 'select2';
import 'imarc-boilerplate/js/dropdown.plugin';
import './mobileNavigation.plugin';
import 'imarc-boilerplate/js/stickyHeader.plugin';
import 'slick-carousel/slick/slick.min.js';
import 'imarc-boilerplate/js/dropdown.plugin';
import './lib/robofilter.js';
import 'sticky-kit/dist/sticky-kit.js';
import Siema from 'siema';


$(document).ready(function(){

    /* Dropdown Filter Logic */
    $('[data-select]').select2({
        minimumResultsForSearch: -1
    });

    $('[data-select]').each(function(i){
        var select = $(this);
        var paramName = select.attr('name');
        var value = findGetParameter(paramName);

        if (value) {
            select.val(value).trigger('change');
        }
    });

    $('.filter-trigger').on('click', function() {
        var dataKey = $(this).attr('data-key');
        var dataValue = $(this).attr('data-value');

        $('select[name="' + dataKey + '"]').val(dataValue).trigger('change');
    });

    $('b[role="presentation"]').hide();

    $('[data-robofilter]').robofilter();

    if($('.entry-list').length) {
        if(!($('.entry-list')[0].childElementCount)) {
            var noResultsText = (typeof $(".entry-list").data('noresults') !== 'undefined') ? $('.entry-list').data('noresults') : 'No Results Found';
            $('.entry-list').html('<p class="no-results">' + noResultsText + '</p>');
        }
    }
    if($('#people').length) {
    	$('select[name="region"]').on('select2:select', function (e) {
    		$('.people-list').css('display','flex');
            $('form#filter').css('display','block');
    	});
    	$('.regions button').on('click', function() {
    		$('.people-list').css('display','flex');
            $('form#filter').css('display','block');
    	});
    }

    /* Photo Modal */
    $('.related-properties').on('click', '.photo-set', function() {
        var photos = $(this).html();
        var photoCount = $(this).children().length;

        $('.slick-container').html(photos);
        $('.slide-nav').html(photos);
        $('.photo-view').css('visibility', 'visible');
        $('.photo-view').css('opacity', '1');

        $('.slick-container').slick({
            slidesToScroll: 1,
            asNavFor: '.slide-nav',
            touchThreshold: 5
        });

        $('.slide-nav').slick({
            slidesToShow: photoCount,
            asNavFor: '.slick-container',
            focusOnSelect: true,
            centerMode: true,
            arrows: true,
            responsive: [
            {
              breakpoint: 768,
              settings: {
                slidesToShow: photoCount
              }
            }
            ]
        });

        if(photoCount < 2 || $(window).width() > 1024) {
            $('.swipe').css('display', 'none');
        } else {
            $('.swipe').css('display', 'block');
        }
    });

    $('.close').click(function() {
        $('.photo-view').css('visibility', 'hidden');
        $('.photo-view').css('opacity', '0');
        $('.slick-container').slick('unslick');
        $('.slide-nav').slick('unslick');
    });

    $(window).scroll(function() {
        if($(window).scrollTop() > 10){
            $('body').addClass('scrolled');
        }else{
            $('body').removeClass('scrolled');
        }
    });
    $(window).scroll();

    /* Person Modal */
    $('.people-list').on('click', '.person',function(){
        var bodyFix = {
            overflow: "hidden"
        };
        var bio = $(this).find('.bio-hidden')[0].innerHTML;
        var name = $(this).find('.name')[0].innerHTML;
        var photo = $(this).find('img').attr('data-modal');
        $('.detail-modal').css('display', 'flex');
        $('.detail-modal').css('opacity', 1);

        $('.modal-photo').attr('src', photo);
        $('.title').html(name);
        $('.bio').html(bio);
        $('html').css(bodyFix);
    });

    $('img.close').click(function() {
        $('.detail-modal').css('display', 'none');

        $('.modal-photo').attr('src', '');
        $('.title').html('');
        $('.bio').html('');
        $('html').css("overflow", "unset");
    });

    /* Modal Relocation */
    if($('.photo-view').length) {
        $('.photo-view').prependTo('body');
    }

    let $navPrimary = $('nav.primary');
    $navPrimary.dropdown();
    $('.mobile').mobileNavigation();

    /* Vehicle Modal */
    $('.entry-list').on('click', '.entry-tile', function(){
        var bodyFix = {
            overflow: "hidden"
        };
        var content = $(this).find('.vehicle-data-hidden')[0].innerHTML;
        $('.vehicle-detail .vehicle-content').html(content);
        $('.vehicle-detail').css('display', 'flex');
        $('html').css(bodyFix);
    });

    $('.vehicle-detail').on('click', 'img.close', function(){
        $('.vehicle-detail').css('display', 'none');
        $('.vehicle-detail .vehicle-content').html('');
        $('html').css("overflow", "unset");
    });

    /* Open external links and pdfs in new tab */
    $('a.new-window,a[href$=".pdf"]').click(function(e) {
        e.preventDefault();
        window.open($(this).prop('href'));
    });

    /* Stick element to top of parent */
    $('.pin').stick_in_parent({
        offset_top: 150
    });

    let externalLinks = 0;
    // Handle elements which are requesting confirmation before continuing
    $('a').each(function(){
        window.linkPopups.forEach((item) => {
            const domainIsEmpty = (item.domain == "")
            const domainIsAllWhitespace = (item.domain.replace(/\s/g, '').length == 0)
            const domainMatchesCurrent = window.location.hostname.includes(item.domain) || item.domain.includes(window.location.hostname)
            if (domainIsEmpty || domainIsAllWhitespace || domainMatchesCurrent) {
                return
            }
            if (this.href.includes(item.domain)) {
                externalLinks++
                console.log(item.popupMessage)
                $(this).attr("data-js-confirm", item.popupMessage)
            }
        });
    })
    console.log("Links marked as external: " + externalLinks);
    // Handle elements which are requesting confirmation before continuing
    $('[data-js-confirm]').each(function(){
        $(this).on('click', function(e) {
            let confirmMsg = $(this).data('js-confirm');
            if (!confirm(confirmMsg)) {
                e.preventDefault();
            }
        })
    })

    $('[data-key="region"]').on('click', function() {
        $('[data-region-show]').each(function() {
            $(this).hide();
        });
        const region = $(this).data('value');
        $('[data-region-show="' + region + '"]').show();
    });
});


var findGetParameter = function(parameterName) {
    var result = null,
        tmp = [];
    var items = location.search.substr(1).split("&");
    for (var index = 0; index < items.length; index++) {
        tmp = items[index].split("=");
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
    }
    return result;
}

function handleCookieConsent(cookieConsent)
{
    if (cookieConsent === 'allow') {
        gtag('consent', 'update', {
            'ad_storage': 'granted',
            'analytics_storage': 'granted',
        });

    } else if (cookieConsent === 'deny') {
        gtag('consent', 'update', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
        });
    } 
}

// Cookie Banner
$(document).ready(function(){
    // Check if the user already accepted
    var cookieConsent = window.localStorage.getItem('cookieConsent');
    if (cookieConsent) {
        $('#cookie-banner').hide();
        handleCookieConsent(cookieConsent);
    }

    $("#cookie-accept").click(function(){
        // Save on LocalStorage
        window.localStorage.setItem('cookieConsent', 'allow');
        handleCookieConsent(window.localStorage.getItem('cookieConsent'));
        $('#cookie-banner').hide();
    }); 
    $("#cookie-close").click(function(){
        // Save on LocalStorage
        window.localStorage.setItem('cookieConsent', 'deny');
        handleCookieConsent(window.localStorage.getItem('cookieConsent'));
        $('#cookie-banner').hide();
    }); 
});

// Alert Banner
let mySiema = new Siema({
    duration: 750,
    loop: true,
});
let prev = document.querySelector('.prev');
let next = document.querySelector('.next');

setInterval(() => mySiema.next(), 6000);
prev.addEventListener('click', () => mySiema.prev());
next.addEventListener('click', () => mySiema.next());

// Alert Banner Exit Button
$(document).ready(function(){

    const homeHeader = document.querySelector('.header-container');


    $('#exit-alert').keypress(function (e) {
        if (e.which == 13) {
            $('.alert-banner').hide();
            $('.alert-banner-padding').hide();
            homeHeader.style.paddingTop = '2rem'
        }
      });
    $("#exit-alert").click(function(){
        $('.alert-banner').hide();
        $('.alert-banner-padding').hide();
        homeHeader.style.paddingTop = '2rem'
    }); 

});

// Alert Banner & Heading Padding 
const alertBanner = document.querySelector('.alert-banner');
const homeHeader = document.querySelector('.header-container');

document.addEventListener('DOMContentLoaded', e => {

    if(alertBanner.style.display !== 'none'){
        homeHeader.style.paddingTop = '5rem'
    } else {
        homeHeader.style.paddingTop = '2rem'
    }
})

// add all the elements inside modal which you want to make focusable
const  focusableElements =
    '#cookieDescription, #cookie-accept, #cookie-close';
const cookieConsent = document.querySelector('#cookie-banner'); // select the modal by it's id

const firstFocusableElement = cookieConsent.querySelectorAll(focusableElements)[0]; // get first element to be focused inside modal
const focusableContent = cookieConsent.querySelectorAll(focusableElements);
const lastFocusableElement = focusableContent[focusableContent.length - 1]; // get last element to be focused inside modal


document.addEventListener('keydown', function(e) {
  let isTabPressed = e.key === 'Tab' || e.keyCode === 9;

  if (!isTabPressed) {
    return;
  }

  if (e.shiftKey) { // if shift key pressed for shift + tab combination
    if (document.activeElement === firstFocusableElement) {
      lastFocusableElement.focus(); // add focus for the last focusable element
      e.preventDefault();
    }
  } else { // if tab key is pressed
    if (document.activeElement === lastFocusableElement) { // if focused has reached to last focusable element then focus first focusable element after pressing tab
      firstFocusableElement.focus(); // add focus for the first focusable element
      e.preventDefault();
    }
  }
});

firstFocusableElement.focus();